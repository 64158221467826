import React from "react";
import { useState } from "react";
import { storage, db } from "../../firebase";
import { toast } from "react-toastify";
import { Timestamp, collection, addDoc } from "firebase/firestore";
import { ref } from "firebase/storage";
import { uploadBytesResumable, uploadBytes } from "firebase/storage";
import { getDownloadURL } from "firebase/storage";
import "react-toastify/dist/ReactToastify.css";

//  const { address, branch_name, description, location } = Form;

export default function AddBranches() {
  const [branch, setBranch] = useState({
    branch_name: "",
    description: "",
    location: "",
    image: "",
    createdAt: Timestamp.now().toDate(),
  });
  const [progress, setProgress] = useState(0);

  const handleInputChange = (e) => {
    setBranch({ ...branch, [e.target.name]: e.target.value });
  };

  const handelImageChange = (e) => {
    setBranch({ ...branch, [e.target.name]: e.target.files[0] });
    console.log(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !branch.branch_name ||
      !branch.description ||
      !branch.image ||
      !branch.location
    ) {
      toast.error("PLEASE FILL ALL THE FIELDS");
      return;
    }

    const storageRef = ref(
      storage,
      `/Branch_images/${Date.now()}${branch.image.name}`
    );

    uploadBytes(storageRef, branch.image).then((snapshot) => {
      console.log("Uploaded a blob or file!");
    });

    const uploadImage = uploadBytesResumable(storageRef, branch.image);
    console.log(uploadImage);
    uploadImage.on(
      "state_changed",
      (snapshot) => {
        const progressPercent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progressPercent);
      },
      (err) => {
        toast.error("ERROR");
      },
      () => {
        setBranch({
          branch_name: "",
          description: "",
          location: "",
          image: "",
        });

        getDownloadURL(uploadImage.snapshot.ref).then((url) => {
          const branchRef = collection(db, "branches");
          addDoc(branchRef, {
            branch_name: branch.branch_name,
            description: branch.description,
            location: branch.location,
            imageURL: url,
            createdAt: Timestamp.now().toDate(),
          })
            .then(() => {
              toast.success("Branch Added Succesfully");
              setProgress(0);
              console.log(branch);
            })
            .catch((err) => {
              toast.error("ERROR ADDING BRANCH");
            });
          console.log(branch);
        });
      }
    );
  };

  return (
    <div className="flex-col justify-center items-center p-10 m-10">
      <div className="m-10" >
        <h1>BRANCH ADDER</h1>
        <form action="">
          <div>
            <input
              className="border-2 m-2  indent-2"
              type="text"
              placeholder="branch_name"
              name="branch_name"
              value={branch.branch_name}
              onChange={(e) => handleInputChange(e)}
            />
            {/* <input
            className="border-2 m-2 indent-2"
            type="text"
            placeholder="address"
            name="address"
            value={branch.address}
            onChange={(e) => handleInputChange(e)}
          /> */}
          </div>
          <div>
            <input
              className="border-2 m-2 indent-2"
              type="description"
              placeholder="description"
              name="description"
              value={branch.description}
              onChange={(e) => handleInputChange(e)}
            />
            <input
              className="border-2 m-2 indent-2"
              type="text"
              placeholder="location"
              name="location"
              value={branch.location}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div>
            <input
              className="border-2 m-2 indent-2"
              type="file"
              name="image"
              // accept="image/*"
              onChange={(e) => handelImageChange(e)}
            />
          </div>
          <div className="p-2 ml-auto">
            <button
              type="submit"
              className=" bg-black text-white text-center p-2 rounded-lg "
              onClick={handleSubmit}
            >
              {" "}
              Submit{" "}
            </button>
          </div>
          {progress === 0 ? null : (
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div
                className="bg-blue-600 h-2.5 rounded-full"
                style={{ width: `${progress}%` }}
              >{`Uploading Image ${progress}%`}</div>
            </div>
          )}
        </form>
      </div>
      <div className="m-10 py-10" >
        <h1>EVENT ADDER</h1>
        <form action="">
          <div>
            <input
              className="border-2 m-2  indent-2"
              type="text"
              placeholder="branch_name"
              name="branch_name"
              value={branch.branch_name}
              onChange={(e) => handleInputChange(e)}
            />
            {/* <input
            className="border-2 m-2 indent-2"
            type="text"
            placeholder="address"
            name="address"
            value={branch.address}
            onChange={(e) => handleInputChange(e)}
          /> */}
          </div>
          <div>
            <input
              className="border-2 m-2 indent-2"
              type="description"
              placeholder="description"
              name="description"
              value={branch.description}
              onChange={(e) => handleInputChange(e)}
            />
            <input
              className="border-2 m-2 indent-2"
              type="text"
              placeholder="location"
              name="location"
              value={branch.location}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div>
            <input
              className="border-2 m-2 indent-2"
              type="file"
              name="image"
              // accept="image/*"
              onChange={(e) => handelImageChange(e)}
            />
          </div>
          <div className="p-2 ml-auto">
            <button
              type="submit"
              className=" bg-black text-white text-center p-2 rounded-lg "
              onClick={handleSubmit}
            >
              {" "}
              Submit{" "}
            </button>
          </div>
          {progress === 0 ? null : (
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div
                className="bg-blue-600 h-2.5 rounded-full"
                style={{ width: `${progress}%` }}
              >{`Uploading Image ${progress}%`}</div>
            </div>
          )}
        </form>
      </div>

      
   
    </div>
  );
}
