import React, { useState, useEffect } from 'react';

type changeListProps={
  sectionno:number
}
export const  ChangeList2=(props: changeListProps)=>{
  return (
    <div className="rounded-md flex justify-between p-3 mx-16 my-5 bg-[#ebf0f9] ">
      <h2 className='text-black font-bold mx-10 my-5'>Section {props.sectionno}</h2>
      <div className='justify-right'>
      <button className='rounded-md ml-20 font-bold mr-5 bg-white text-black p-3'>Upload Image</button>
      <button className='rounded-md mr-5 font-bold bg-white text-black py-3 px-5'>Edit Text</button>
      </div>
      
    </div>
  )
}


   


