type homeEventsProps = {
  eventname: string;
  eventdate: number;
  eventmonth: string;
  location: string;
};
export const HomeEvents = (props: homeEventsProps) => {
  return (
    <div  className="">
      <div className="h-52  lg:flex bg-white  rounded p-2  md:p-5 md:pb-10 md:mx-32 lg:mx-5">
        <div>
          <h1 className="font-bold text-[#182B4D] lg:text-4xl text-2xl lg:mb-4 lg:mt-2">
            {props.eventdate}
          </h1>
          <h3 className=" text-[#182B4D] lg:text-xl text-sm">{props.eventmonth}</h3>
        </div>
        <div className=" mx-5">
          <h3 className=" text-[#182B4D] font-medium text-sm pt-1">
            NEXT EVENT
          </h3>
          <h2 className="font-bold px-3 pt-2 text-[#182B4D] ">
            {props.eventname}
          </h2>
          <h2 className="font-bold px-3 pt-2 text-[#182B4D] ">
            {props.location}
          </h2>
        </div>
      </div>
    </div>
  );
};
