import { BrowserRouter, Route, Routes } from "react-router-dom";
import Ourbranches from "./pages/ourBranches/outBranches";
import Home from "./pages/home/home";
import AboutUs from "./pages/aboutUs/aboutUs";
import { JoinUs } from "./pages/joinUs/JoinUs";
import Team from "./pages/team/team";
import Donation from "./pages/donation/donation";
import { Adminuserpage } from "./pages/admin/admin_user_page";
import ContactUs from "./pages/contactUs/contactus";
import AdminLogin from "./pages/adminLogin/adminLogin";
import Media from "./pages/media/media";
import ChangeList2 from "./pages/adminChangeList/adminChangeList2";
import ChangeList1 from "./pages/adminChangeList/adminChangeList1";
import Developer from "./pages/developer/developer";
import React from "react";
import AddBranches from "./pages/admin/addbranches";
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <BrowserRouter>
      <ToastContainer position="top-center" />
      <Routes>
        <Route path="/adminlogin" element={<AdminLogin />} />
        <Route path="/developer" element={<Developer />} />
        <Route path="/adminb" element={<AddBranches />} />
        <Route path="/media" element={<Media />} />
        <Route path="/donation" element={<Donation />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/adminChangeList1" element={<ChangeList1 />} />
        <Route path="/adminChangeList2" element={<ChangeList2 />} />
        <Route path="/branches" element={<Ourbranches />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/team" element={<Team />} />
        <Route path="/joinus" element={<JoinUs />} />
        <Route path="/adminuserpage" element={<Adminuserpage />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
