import React from "react";
interface BranchProps 
{
  imageURL: any;
  location: any;
  branch_name: any;
  description: any;
  id : any;
  createdAt : any;
}
export default function BranchDetails( data : BranchProps) {

  return (
    <div className="lg:flex lg:px-2 md:px-16 lg:h-auto shadow-lg md:m-10 m-2 my-4 ">
      <div className=" basis-2/5 lg:basis-2/5">

        <img className="lg:h-[100%] md:w-[100%]" src={data.imageURL} alt="" />
      </div>
      <div className="basis-3/5 lg:basis-3/5 bg-[#182B4D] md:px-4">
        <div className="text-white px-4 py-6 xl:py-28  lg:py-8 lg:h-auto md:py-8  text-justify  lg:px-12 md:px-4">
          <p className="font-bold text-sm xl:text-3xl lg:text-2xl md:text-lg sm:text-lg">{data.branch_name}</p>
          <button className="bg-white hover:bg-[#2f589e] mt-6 text-[#182B4D] font-semibold hover:text-white xl:py-1 xl:px-4 lg:px-2 lg:py-1 md:px-1 md:py-1  px-1 py-1 border border-blue-500 hover:border-transparent rounded">
            <a className="xl:text-2xl lg:text-xl md:text-xl sm:text-lg text-lg font-bold md:text" href={data.location}>Location</a>
          </button>
          <p className="xl:pt-7 lg:pt-5 md:pt-4 sm:pt-4 pt-4 text-sm xl:text-xl lg:text-lg md:text-sm">{data.description}</p>
        </div>
      </div>
    </div> 
  );
}
// xl:text-2xl text-lg
// 
