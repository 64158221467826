import React from "react";
//import { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Navigation, Pagination } from "swiper";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
//import { db } from "../../firebase";

import data from "../../utils/content.json";
//import { collection } from "firebase/firestore";
import { HomeEvents } from "../../components/homeEvents/homeEvents";

// animation
import "animate.css";
const p1 = require("../../images/home/landing.jpg");
const p2 = require("../../images/home/p2.jpg");
const p3 = require("../../images/home/p4.jpg");
const founderimg = require("../../images/home/IMG_3327.jpg");
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

// const docref = collection(db,'events')
// console.log(docref)

export default function home() {
  const Events = [
    {
      eventname: "गौरव शिक्षणाचा",
      eventdate: 3,
      eventmonth: "Dec",
      location:
        "राजीव गांधी ई - लर्निंग स्कूल, विजय तेंडुलकर नाट्यगृह, सहकार नगर, पुणे.",
    },
  ];

  return (
    <div>
      <div>
        <Navbar />
      </div>
      <section className="">
        <Carousel
          swipeable={false}
          draggable={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={1000}
          keyBoardControl={true}
          customTransition="transform 100ms ease-in-out"
          transitionDuration={500}
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          <div className="md:mt-5">
            <img
              className="  h-[50vh] w-[full]  md:w-[84%] mx-auto  md:h-[80vh]  "
              src={p3}
              alt=""
            />
          </div>
          <div className="md:mt-5">
            <img
              className="h-[50vh] w-[full]  md:w-[84%] mx-auto   md:h-[80vh] "
              src={p2}
              alt=""
            />
          </div>
          <div className="md:mt-5">
            <img
              className="  h-[50vh] w-[full]  md:w-[84%] mx-auto   md:h-[80vh]"
              src={p1}
              alt=""
            />
          </div>
        </Carousel>
      </section>

      {/* upcoming events */}
      <section>
        <div className=" text-center bg-[#182B4D] w-10/12 align-middle mt-10 justify-center m-auto h-auto px-5">
          <div className="flex px-20 lg:justify-start justify-center pt-5">
            <h1 className="font-bold  text-white text-2xl mb-10">
              Upcoming Events
            </h1>
          </div>

          <div className="lg:flex lg:justify-between lg:items-center lg:px-20 pb-10">
            {Events.length == 1 ? (
              <HomeEvents {...Events[0]} />
            ) : (
              <Swiper
                slidesPerView={1}
                spaceBetween={50}
                slidesPerGroup={1}
                loop={true}
                loopFillGroupWithBlank={true}
                pagination={{
                  clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                breakpoints={{
                  // when window width is >= 768px
                  769: {
                    width: 769,
                    slidesPerView: 2,
                  },
                }}
                className=" mx-24 px-24"
              >
                {Events.map((event) => {
                  return (
                    <SwiperSlide>
                      <HomeEvents {...event} />.{"  "}
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            )}
          </div>
        </div>
      </section>
      {/* Our mission Vision */}
      <section>
        <div className=" text-center bg-[#182B4D] w-10/12 align-middle mt-10 justify-center m-auto h-auto p-1">
          <div className="m-8 lg:mx-16 my-8 p-4 ">
            <div className="lg:text-left justify-center font-bold text-white lg:text-2xl pl-4 text-xl pt-2 ">
              Our Vision
            </div>
            <div className="lg:text-left md:text-left text-center md:p-4 p-1 mt-5 text-sm  md:text-base text-white lg:text-xl">
              {data.homepagevision}
            </div>
          </div>
        </div>
        <div className=" text-center bg-[#182B4D] w-10/12 align-middle mt-10 justify-center m-auto h-auto p-1">
          <div className="m-8 lg:mx-16  lg:my-8 mb-4 p-4">
            <div className="lg:text-left justify-center font-bold text-white lg:text-2xl pl-4 text-xl">
              Our Mission
            </div>
            <div className="lg:text-left text-justify p-1  md:text-left mt-5 md:p-4  text-white lg:text-xl text-sm  md:text-base">
              <div>{data.homepagemission}</div>

              <br />
              <div className="pt-2">1. {data.homepagem1}</div>

              <div className="pt-2">2. {data.homepagem2}</div>

              <div className="pt-2">3. {data.homepagem3}</div>

              <div className="pt-2">4. {data.homepagem4}</div>

              <div className="pt-2">5. {data.homepagem5}</div>

              <div className="pt-2">6. {data.homepagem6}</div>

              <div className="pt-2">7. {data.homepagem7}</div>

              <div className="pt-2">8. {data.homepagem8}</div>

              <div className="pt-2">9. {data.homepagem9}</div>

              <div className="pt-2">10. {data.homepagem10}</div>
            </div>
          </div>
        </div>
      </section>
      {/* Founders messages   */}
      {/* <section></section>
      <section></section> */}
      {/* Founders messages   */}
      <section>
        <div className="lg:flex md:m-24 md:p-4 p-4 m-8 shadow-2xl">
          <div className="lg:mx-12 lg:w-1/4 md:p-8 mx-6 lg:p-2 flex justify-center animate__animated  animate__fadeInLeft ">
            <img
              className="md:h-56 h-42 my-4 rounded-full  "
              src={founderimg}
              alt=""
            />
          </div>
          <div className="md:px-6 lg:w-3/4 px-2">
            <div className="m-4 md:text-3xl my-auto font-bold lg:text-left text-center text-xl">
              <h1>Founder's Message</h1>
            </div>
            <div className="m-4 lg:text-left text-xs md:text-lg text-justify justify-center animate__animated  animate__fadeInRight ">
              <p>
                Abhijeet desires to close this school for the reason he thinks
                that there should be no underprivileged street kids inhabited
                along roadside who is in need of education. As our Indian
                Constitution guarantees every individual right to education and
                if for this basic fundamental right, we have to fight or work
                for this cause then there is no misfortune other than this.
              </p>
              <br />
              <p>
                Abhijeet’s vision is ”In India every individual soul should
                receive education be it an underprivileged street kid or any
                person from elite class. Everyone should get free, fair and
                quality education.”
              </p>
              <p className="font-semibold">
                {" "}
                Abhijeet will always work for this noble cause throughout
                journey.
              </p>
            </div>
          </div>
        </div>
      </section>

      <div>
        <Footer />
      </div>
    </div>
  );
}
