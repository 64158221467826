import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import { ChangeList2 } from "../../components/adminChangeList/changeList2";

export default function adminChangeList2() {
    return (

        <div>
            <div>
                <Navbar />
            </div>
            {/*title */}
            <section>
                <div>
                    <h1 className="font-bold text-3xl m-10">Home</h1>
                </div>
            </section>

            {/*changes button*/}
            <div>
                <ChangeList2 sectionno={1} />
                <ChangeList2 sectionno={2} />
                <ChangeList2 sectionno={3} />
                <ChangeList2 sectionno={4} />
                <ChangeList2 sectionno={5} />
                <ChangeList2 sectionno={6} />

            </div>
           

            <div>
                <Footer />
            </div>

        </div>
    );
}