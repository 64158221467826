import React from "react";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import GoogleIcon from "@mui/icons-material/Google";

export default function Footer() {
  return (
    <div className="">
      <div className=" flex  bg-[#182B4D] justify-center items-center">
        <div className=" w-[30%]  ">
          <div className=" my-8  ">
            <ul className="">
              <a href="/aboutus">
                {" "}
                <li className="my-2 p-1   border-white text-center md:text-xl text-base font-medium text-white ">
                  About US
                </li>
              </a>
              <a href="/branches">
                <li className="my-2 p-1   border-white text-center md:text-xl text-base font-medium text-white ">
                  Branches
                </li>
              </a>
              <a href="/team">
                <li className="my-2 p-1   border-white text-center md:text-xl text-base font-medium text-white ">
                  Team
                </li>
              </a>
            </ul>
          </div>
        </div>
        <div className=" w-[30%]   ">
          <div className=" my-8  ">
            <ul>
              <a href="/media">
                {" "}
                <li className="my-2 p-1   border-white text-center md:text-xl text-base font-medium text-white ">
                  {" "}
                  Media
                </li>
              </a>
              <a href="/faq">
                {" "}
                <li className="my-2 p-1   border-white text-center md:text-xl text-base font-medium text-white ">
                  FAQ's
                </li>
              </a>
              <a href="/contactus">
                {" "}
                <li className="my-2 p-1   border-white text-center md:text-xl text-base font-medium text-white ">
                  {" "}
                  Contact Us
                </li>
              </a>
            </ul>
          </div>
        </div>
        <div className=" w-[40%] ">
          <div className="md:my-4  ">
            <div className=" items-center justify-center flex ">
              <a href="/joinus">
                <button className="rounded-md m-1 md:m-2 bg-white text-black md:p-6 p-1 md:w-40 w-[100%] my-4 md:font-medium  hover:bg-[#a5a5a8]">
                  <h1 className="font-extrabold md:text-lg text-base ">
                    Join Us
                  </h1>
                </button>
              </a>
            </div>
            <div>
              <div>
                <h1 className="text-white text-center md:text-lg text-base font-bold">
                  Connect With Us!
                </h1>
              </div>
              <div className="flex items-center justify-center">
                <a
                  className="md:p-2 p-1 "
                  href="https://www.facebook.com/dadachishala/"
                >
                  <FacebookIcon
                    className="icons"
                    style={{ color: "white" }}
                  ></FacebookIcon>
                </a>
                <a
                  className="md:p-2 p-1"
                  href="https://www.instagram.com/dadachishala/?hl=en"
                >
                  <InstagramIcon
                    className="icons"
                    style={{ color: "white" }}
                  ></InstagramIcon>
                </a>
                <a
                  className="md:p-2 p-1"
                  href="https://www.youtube.com/channel/UCPzPQ0_GSdJGgHXzF6_BU5A"
                >
                  <YouTubeIcon
                    className="icons"
                    style={{ color: "white" }}
                  ></YouTubeIcon>
                </a>
                <a
                  className="md:p-2 p-1"
                  href="https://www.google.com/search?q=dadachi+shala&rlz=1C1ONGR_enIN994IN994&oq=dadachi+shala+&aqs=chrome..69i57j35i39l2j69i59j0i512j69i60l3.4469j0j7&sourceid=chrome&ie=UTF-8"
                >
                  <GoogleIcon
                    className="icons"
                    style={{ color: "white" }}
                  ></GoogleIcon>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <a href="/developer">
        <div className="bg-[#182B4D] flex justify-center pb-2 text-white border-t-2 p-1 border-white ">
          Developed by Students of PICT
        </div>
      </a>
    </div>
  );
}
