import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
//import data1 from "../../utils/content.json"
import BranchDetails from "../../components/branchDetails/BranchDetails";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "../../firebase";

const bp1 = require("../../images/home/main_image.jpg");

export default function Ourbranches() {
  const [branch, setBranches] = useState([{}]);
  useEffect(() => {
    const branchRef = collection(db, "branches");
    const q = query(branchRef, orderBy("createdAt", "desc"));
    console.log(q)
    // console.log(branchRef);
    onSnapshot(q, (snapshot) => {
      const branches = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBranches(branches);
      console.log(branches);
    });
  }, []);

  const branchElements = branch.map((element) => {
    return (
      <BranchDetails
        imageURL={undefined}
        location={undefined}
        branch_name={undefined}
        description={undefined}
        id={undefined}
        createdAt={undefined}
        {...element}
      />
    );
  });

  return (
    <div className="">
      <div>
        <Navbar />
      </div>
      <section className="">
        <div className="lg:flex  bg-[#182B4D]">
          <div className="basis-2/5 px-12 py-4   lg:basis-2/5 lg:my-auto lg:px-32  md:px-16 md:py-16 sm:px-10 sm:py-12">
            <p className="lg:text-5xl md:text-xl font-bold font-roboto text-center text-white   ">
              Our Branches
            </p>
          </div>
          <div className="basis-3/5 lg:basis-3/5 md:basis-3/5">
            <img className="lg:h-[100%] md:h-[50%]" src={bp1} alt="" />
          </div>
        </div>
      </section>
      <br></br>
      <br></br>
      <div className="lg:flex px-6 lg:px-14 md:px-4">
        <div className="md:px-16 lg:px-16">{branchElements}</div>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
}
