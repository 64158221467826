import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
// import TeamCard from "../../components/teamCard/TeamCard";

import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

//const volunteerImage = require("../../images/team/Image1.png");
const abhijeet = require("../../images/team/abhijeet.png");
//const punit = require("../../images/team/punit_patil.png");
const shubhamW = require("../../images/team/shubham.jpg");
const shubhamM = require("../../images/team/shubham_mane.png");
//const akshay = require("../../images/team/akshay_rajdeo.png");
const anjali = require("../../images/team/anjali.jpg");
const group = require("../../images/shared/team.JPG");
//const person = require("../../images/team/Image.png");
const snehal = require("../../images/team/snheal.jpg")

export default function team() {
  return (
    <div>
      <div>
        <Navbar></Navbar>
      </div>
      <section className="bg-[#182B4D]">
        <div className=" flex-col justify-center items-center p-8">
          <div className=" text-center flex-col items-center lg:p-10 lg:pt-2 md:p-10 justify-center">
            <h1 className="lg:text-4xl md:text-3xl text-xl text-white font-bold">
              Meet our Team
            </h1>
            <br />
            <p className="text-gray-400 md:text-lg text-sm  text-center  ">
              Our Passionate and Enthusiastic Core Team member include
            </p>
          </div>
          <div className=" md:flex items-center    lg:my-5 md:mx-4 my-4 justify-evenly">
            <div className=" ">
              <div className="flex-col  bg-[#182B4D] ">
                <div className="">
                  <img
                    className="rounded-lg m-auto  lg:w-[35vh] md:w-[25vh]  w-[70%]  "
                    src={abhijeet}
                    alt=""
                  />
                </div>
                <div className=" md:p-1 md:pb-0  md:text-base pt-2 text-base text-center  ">
                  <p className="font-medium text-white">Abhijeet Pokharnikar</p>
                </div>
                <div className=" text-center md:text-base text-sm ">
                  <p className="text-gray-400 ">Founder</p>
                </div>
                <div className=" flex justify-center items-center ">
                  <div className="md:px-2 md:p-1  px-2 py-1  ">
                    <a href="https://twitter.com/abhijeetpokhar2">
                      <button>
                        {" "}
                        <TwitterIcon
                          className="icons"
                          style={{ color: "#FAF9F6" }}
                        ></TwitterIcon>{" "}
                      </button>
                    </a>
                  </div>
                  <div className="md:px-2  px-2 py-1 ">
                    <a href="https://www.facebook.com/abhijeet.dada.96/">
                      <button>
                        <FacebookIcon
                          className="icons"
                          style={{ color: "#FAF9F6" }}
                        ></FacebookIcon>
                      </button>
                    </a>
                  </div>
                  <div className="md:px-2  px-2 py-1 ">
                    <a href="https://www.instagram.com/abhijeet_pokharnikar_/">
                      <button>
                        <InstagramIcon
                          className="icons"
                          style={{ color: "#FAF9F6" }}
                        ></InstagramIcon>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className=" ">
              <div className="  flex-col my-12 bg-[#182B4D] ">
                <div className="">
                  <img
                    className="rounded-lg m-auto  lg:w-[35vh] md:w-[25vh]  w-[70%]  "
                    src={shubhamM}
                    alt=""
                  />
                </div>
                <div className=" md:p-1 md:pb-0  md:text-base pt-2 text-base text-center  ">
                  <p className="font-medium text-white">Shubham Mane</p>
                </div>
                <div className=" text-center md:text-base text-sm ">
                  <p className="text-gray-400 ">Founder</p>
                </div>
                <div className=" flex justify-center items-center ">
                  <div className="md:px-2 md:p-1  px-2 py-1  ">
                    <button>
                      {" "}
                      <TwitterIcon
                        className="icons"
                        style={{ color: "#FAF9F6" }}
                      ></TwitterIcon>{" "}
                    </button>
                  </div>
                  <div className="md:px-2  px-2 py-1 ">
                    <button>
                      <FacebookIcon
                        className="icons"
                        style={{ color: "#FAF9F6" }}
                      ></FacebookIcon>
                    </button>
                  </div>
                  <div className="md:px-2  px-2 py-1 ">
                    <a href="https://instagram.com/_shubham__mane?igshid=YmMyMTA2M2Y=">
                      <button>
                        <InstagramIcon
                          className="icons"
                          style={{ color: "#FAF9F6" }}
                        ></InstagramIcon>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" md:flex my-12 justify-center">
            <div className="lg:mx-10 md:mx-4 my-10  ">
              <div className="flex-col  bg-[#182B4D] ">
                <div className="">
                  <img
                    className="rounded-lg m-auto  lg:w-[35vh] md:w-[25vh]  w-[70%]  "
                    src={snehal}
                    alt=""
                  />
                </div>
                <div className=" md:p-1 md:pb-0  md:text-base pt-2 text-base text-center  ">
                  <p className="font-medium text-white">Snehal Bhosale</p>
                </div>
                <div className=" text-center md:text-base text-sm ">
                  <p className="text-gray-400 ">Core Team Member</p>
                </div>
                <div className=" flex justify-center items-center ">
                  <div className="md:px-2 md:p-1  px-2 py-1  ">
                    <button>
                      {" "}
                      <TwitterIcon
                        className="icons"
                        style={{ color: "#FAF9F6" }}
                      ></TwitterIcon>{" "}
                    </button>
                  </div>
                  <div className="md:px-2  px-2 py-1 ">
                    <button>
                      <FacebookIcon
                        className="icons"
                        style={{ color: "#FAF9F6" }}
                      ></FacebookIcon>
                    </button>
                  </div>
                  <div className="md:px-2  px-2 py-1 ">
                    <a href="https://instagram.com/punit_dhaybar_patil_96?igshid=YmMyMTA2M2Y=">
                      <button>
                        <InstagramIcon
                          className="icons"
                          style={{ color: "#FAF9F6" }}
                        ></InstagramIcon>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:mx-12 md:mx-4 my-10">
              <div className="flex-col  bg-[#182B4D] ">
                <div className="">
                  <img
                    className="rounded-lg m-auto  lg:w-[35vh] md:w-[25vh]  w-[70%]  "
                    src={shubhamW}
                    alt=""
                  />
                </div>
                <div className=" md:p-1 md:pb-0  md:text-base pt-2 text-base text-center  ">
                  <p className="font-medium text-white">Shubham walhekar </p>
                </div>
                <div className=" text-center md:text-base text-sm ">
                  <p className="text-gray-400 ">Core Team Member</p>
                </div>
                <div className=" flex justify-center items-center ">
                  <div className="md:px-2 md:p-1  px-2 py-1  ">
                    <button>
                      {" "}
                      <TwitterIcon
                        className="icons"
                        style={{ color: "#FAF9F6" }}
                      ></TwitterIcon>{" "}
                    </button>
                  </div>
                  <div className="md:px-2  px-2 py-1 ">
                    <a href="https://www.facebook.com/akshay.rajdeo">
                      <button>
                        <FacebookIcon
                          className="icons"
                          style={{ color: "#FAF9F6" }}
                        ></FacebookIcon>
                      </button>
                    </a>
                  </div>
                  <div className="md:px-2  px-2 py-1 ">
                    <a href="https://www.instagram.com/shubham_walhekar_/?igshid=YmMyMTA2M2Y%3D">
                      <button>
                        <InstagramIcon
                          className="icons"
                          style={{ color: "#FAF9F6" }}
                        ></InstagramIcon>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:mx-14 md:mx-4 my-10 ">
              <div className="flex-col  bg-[#182B4D] ">
                <div className="">
                  <img
                    className="rounded-lg m-auto  lg:w-[35vh] md:w-[25vh]  w-[70%]  "
                    src={anjali}
                    alt=""
                  />
                </div>
                <div className=" md:p-1 md:pb-0  md:text-base pt-2 text-base text-center  ">
                  <p className="font-medium text-white">Anjali shinde</p>
                </div>
                <div className=" text-center md:text-base text-sm ">
                  <p className="text-gray-400 ">Core Team Member</p>
                </div>
                <div className=" flex justify-center items-center ">
                  <div className="md:px-2 md:p-1  px-2 py-1  ">
                    <button>
                      {" "}
                      <TwitterIcon
                        className="icons"
                        style={{ color: "#FAF9F6" }}
                      ></TwitterIcon>{" "}
                    </button>
                  </div>
                  <div className="md:px-2  px-2 py-1 ">
                    <button>
                      <FacebookIcon
                        className="icons"
                        style={{ color: "#FAF9F6" }}
                      ></FacebookIcon>
                    </button>
                  </div>
                  <div className="md:px-2  px-2 py-1 ">
                    <a href="https://instagram.com/dt.anjalishinde808?igshid=YmMyMTA2M2Y=">
                      <button>
                        <InstagramIcon
                          className="icons"
                          style={{ color: "#FAF9F6" }}
                        ></InstagramIcon>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className=" flex-col justify-center items-center bg-white">
        <div className=" text-center flex-col items-center p-12 justify-center">
          <h1 className="lg:text-4xl md:text-3xl text-xl text-[#182B4D] font-bold">
            Meet our Team
          </h1>
          <br />
          <p className="text-gray-500 md:text-lg text-sm mx-auto md:w-[60%]">
            Akshay Rajdeo , Punit Patil , Gayatri Bahirat , Gayatri Gawande ,
            Jagruti Uttekar, Sohail Shaikh , Narayan Gire , Samadhan Shinde ,
            Sayali Patil , Harsh Shinde , Prithviraj Chouhan , Atharva
            Shegaonkar
          </p>
        </div>
        <div className=" md:m-10 m-5 mt-2 items-center justify-center flex ">
          <img className="md:h-[90vh] h-auto w-[80%] " src={group} alt="" />
        </div>
      </section>
      <div>
        <Footer></Footer>
      </div>
    </div>
  );
}
