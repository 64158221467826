import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import data from "../../utils/content.json";
const group  = require("../../images/shared/team.JPG")
const staffimg = require("../../images/shared/staff.png");
// const data = JSON.parse(jsonString)

export default function Aboutus() {
  return (
    <div>
      <div>
            <Navbar/>
        </div>
      <div>
        <div className="lg:flex bg-[#182B4D]  md:mx-28 md:my-20 md:p-8 mx-8 my-8 p-2 text-white rounded-lg">
          <div className="lg:w-1/3 lg:text-left md:m-4 md:p-4 md:font-bold md:text-3xl text-xl font-bold text-center m-4 p-2 ">
            <h2 className="text-2xl lg:text-3xl">{data.AbtTitle1}</h2>
            <br />

            {/* <p>We are non-profit organization working for a greater cause...</p> */}
          </div>
          <div className=" lg:w-2/3 lg:text-left md:m-4 md:p-4  m-4 p-2 text-justify text-xl">
            <p>
              {data.AbtContent1}
            </p>
            <br />
          </div>
        </div>
        <div className="lg:flex bg-[#182B4D]  md:mx-28 md:my-20 md:p-8 mx-8 my-8 p-2 text-white rounded-lg">
          <div className="lg:w-1/3 lg:text-left md:m-4 md:p-4 md:font-bold md:text-3xl text-xl font-bold text-center m-4 p-2 ">
            <h2 className="text-2xl lg:text-3xl">{data.AbtTitle2}</h2>
            <br />
            {/* <p>How it started...</p> */}
          </div>
          <div className=" lg:w-2/3 lg:text-left md:m-4 md:p-4 m-4 p-2 text-justify text-xl ">
            <p>
              {data["AbtContent2.1"]}
            </p>
            <br />
            <br />
            <p>
              {data["AbtContent2.2"]}
            </p>
          </div>
        </div>
        <div className="lg:flex bg-[#182B4D]  md:mx-28 md:my-20 md:p-8 mx-8 my-8 p-2 text-white rounded-lg">
          <div className="lg:w-1/3 lg:text-left md:m-4 md:p-4 md:font-bold md:text-3xl text-xl font-bold text-center m-4 p-2 ">
            <h2 className="text-2xl lg:text-3xl">{data.AbtTitle3}</h2>
            <br />
            {/* <p></p> */}
          </div>
          <div className=" lg:w-2/3 lg:text-left md:m-4 md:p-4 m-4 p-2 text-justify text-xl ">
            <p>
              {data.AbtContent3}
            </p>
            <br />
          </div>
        </div>
        <div className="lg:flex bg-[#182B4D]  md:mx-28 md:my-20 md:p-8 mx-8 my-8 p-2 text-white rounded-lg">
          <div className="lg:w-1/3 lg:text-left md:m-4 md:p-4 md:font-bold md:text-3xl text-xl font-bold text-center m-4 p-2 ">
            <h2 className="text-2xl lg:text-3xl">{data.AbtTitle4}</h2>
            <br />
            {/* <p></p> */}
          </div>
          <div className=" lg:w-2/3 lg:text-left md:m-4 md:p-4 m-4 p-2 text-justify text-xl ">
            <p>
              {data.AbtContent4}
            </p>
            <br />
            {/* <br />
            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sequi
              consectetur harum aspernatur. Totam alias autem nesciunt possimus
              eum, officia nostrum, sint unde obcaecati ducimus, consectetur
              porro? Quos eum voluptatum iste.
            </p> */}
          </div>
        </div>
        <div className="lg:flex bg-[#182B4D]  md:mx-28 md:my-20 md:p-8 mx-8 my-8 p-2 text-white rounded-lg">
          <div className="lg:w-1/3 lg:text-left md:m-4 md:p-4 md:font-bold md:text-3xl text-xl font-bold text-center m-4 p-2 ">
            <h2 className="text-2xl lg:text-3xl">{data.AbtTitle5}</h2>
            <br />
            {/* <p></p> */}
          </div>
          <div className=" lg:w-2/3 lg:text-left md:m-4 md:p-4 m-4 p-2 text-justify text-xl">
            <p>
              <ol className="lg:text-left text-center">
                <li>{data["AbtContent5.1"]}</li>
                <li>{data["AbtContent5.2"]}</li>
                <li>{data["AbtContent5.3"]}</li>
                <li>{data["AbtContent5.4"]}</li>
                <li>{data["AbtContent5.5"]}</li>
                <li>{data["AbtContent5.6"]}</li>
              </ol>
            </p>
            <br />
          </div>
        </div>
      </div>
      <div className="lg:flex lg:my-12 lg:mx-32 lg:p-8 md:my-12 md:mx-32 md:p-8 mx-10 my-12 p-8 shadow-lg">
        <div className=" lg:w-1/2 md:m-4 md:p-2  ">
          <img className="w-full h-full " src={group} alt="" />
        </div>
        <div className="text-[#182B4D] md:m-6 md:p-6 lg:w-1/2 m-2 p-1">
          <h1 className="lg:text-3xl md:text-bold text-xl text-center font-bold">
            Our Interventions
          </h1>
          <br />
          <div className="text-xl text-left">
            <p className="my-4">{data.AbtOurInterventions.AbtInt1}</p>

            <p className="my-4">{data.AbtOurInterventions.AbtInt2}</p>

            <p className="my-4">{data.AbtOurInterventions.AbtInt3}</p>

            <p className="my-4">{data.AbtOurInterventions.AbtInt4}</p>

            <p className="my-4">{data.AbtOurInterventions.AbtInt5}</p>


          </div>
          {/* <div className="flex justify-center">
            <button className="rounded-md bg-[#182B4D] hover:bg-blue-700 text-white md:m-20 m-5 p-2 my-6 md:my-6 md:p-2 font-normal ">
              Read More
            </button>
          </div> */}
        </div>
      </div>
      <div>
            <Footer/>
        </div>
    </div>
  );
}