type changeList1Props={
    sectionname:string
  }
  export const  ChangeList1=(props: changeList1Props)=>{
    return (
      <div className="rounded-md flex justify-between p-3 mx-16 my-5 bg-[#ebf0f9] ">
        <h2 className='text-black font-bold mx-16 my-5 text-xl'>{props.sectionname}</h2>
        <div className='justify-right'>
        <button className='rounded-md ml-20 font-bold mr-5 bg-white text-black p-3'>Change it</button>
       
        </div> 
        
      </div>
    )
  }