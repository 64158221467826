import React from "react";
import { FormEvent, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import GoogleIcon from "@mui/icons-material/Google";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase";
import data from "../../utils/content.json";
export default function ContactUs() {

  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phoneno: "",
    text_area: ""
  });
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
    console.log(state);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log(state);
    if (
      !state.first_name ||
      !state.last_name ||
      !state.phoneno ||
      !state.text_area ||
      !state.email
    ) {
      toast.warn("Please Enter all Input fields!", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      const colRef = collection(db, "contact");

      addDoc(colRef, {
        first_name: state.first_name,
        last_name: state.last_name,
        phoneno: state.phoneno,
        text_area: state.text_area,
      }).then(() => {
        setState({
          first_name: "",
          last_name: "",
          phoneno: "",
          email: "",
          text_area: "",
        });
      });
    }
  };
  return (

    <div>
      <div>
        <Navbar />
      </div>

      {/*donation for education*/}
      <section>
        <div className="bg-[#182B4D] p-10 md:flex md:lex-row justify-around text-white mb-10">
          <div className="md:w-[40%]">
            <div className="flex flex-row">
              <div className="pb-5 ">__________ </div>

              <h2 className=" tracking-widest font-light text-sm ml:5 ml-10 pt-2">
                CONTACT US
              </h2>
            </div>

            <h1 className="md:ml-20 text-4xl font-semibold">
              We'd love to <br />
              hear from you
            </h1>
            <h2 className="font-light md:ml-20 mt-8 text-justify">
           {data.ContactUsQuote}
            </h2>
            <h2 className="flex font-light md:ml-20 mt-1 justify-end lg:mr-20">
           {data.ContactUsAuthor}
            </h2>
            
          </div>
          <div className="justify-items-center mx-auto">
            <h2 className="text-xl font-semibold  mt-10 mb-5 md:my-2 ">Let's Talk!</h2>

            <div className="flex justify-between">
              <div className="">{data.ContactUsName1}</div>
              <a className="hover:underline" href="https://wa.link/z7lly9" target={"_blank"} >{data.ContactUsContact1}</a>
            </div>
            <div className="flex justify-between">
              <div className="">{data.ContactUsName2}</div>
              <a className="hover:underline" href="https://wa.link/4j4gbt" target={"_blank"} >{data.ContactUsContact2}</a>
            </div>
            <h2 className="text-xl font-semibold mb-5  text-center">
              ______________________________
            </h2>
            <h2 className="text-xl font-semibold mt-5">Office Address</h2>
            <h2 className=" ">{data.ContactUsOfficeAddress}</h2>
            <h2 className="text-xl font-semibold mt-5">Branch Address</h2>
            <a href="https://maps.app.goo.gl/r6MaALJg7GXVxgoM9" className=" mb-3">{data.ContactUsBranchAddress}</a>
            <div className="flex items-center justify-center">
                <a
                  className="md:p-2 p-1 "
                  href="https://www.facebook.com/dadachishala/"
                >
                  <FacebookIcon
                    className="icons"
                    style={{ color: "white", }}
                  ></FacebookIcon>
                </a>
                <a
                  className="md:p-2 p-1"
                  href="https://www.instagram.com/dadachishala/?hl=en"
                >
                  <InstagramIcon
                    className="icons"
                    style={{ color: "white", }}
                  ></InstagramIcon>
                </a>
                <a
                  className="md:p-2 p-1"
                  href="https://www.youtube.com/channel/UCPzPQ0_GSdJGgHXzF6_BU5A"
                >
                  <YouTubeIcon
                    className="icons"
                    style={{ color: "white", }}
                  ></YouTubeIcon>
                </a>
                <a
                  className="md:p-2 p-1"
                  href="https://www.google.com/search?q=dadachi+shala&rlz=1C1ONGR_enIN994IN994&oq=dadachi+shala+&aqs=chrome..69i57j35i39l2j69i59j0i512j69i60l3.4469j0j7&sourceid=chrome&ie=UTF-8"
                >
                  <GoogleIcon
                    className="icons"
                    style={{ color: "white",}}
                  ></GoogleIcon>
                </a>
              </div>
          </div>
          
        </div>
      </section>

     {/* formm */}
      <div className="flex border-2 rounded-lg ring-2 ring-gray-300 border-slate-800 w-[80%] align-middle mt-10 justify-center m-auto h-auto mb-10">
          <div className="flex-1">
            <form
              id="addUser"
              onSubmit={handleSubmit}
              className=" align-middle m-10"
            >
              <h3 className="text-xl font-bold mb-2">Contact Us</h3>

              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="relative z-0 mb-6 w-full group">
                  <label id="first_name" htmlFor="first_name"></label>
                  <p className="text-sm">First Name</p>
                  <input
                    type="text"
                    name="first_name"
                    id="first_name"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder="First Name"
                    value={state.first_name}
                    onChange={(e) => handleInputChange(e)}
                  ></input>
                </div>
                <div className="relative z-0 mb-6 w-full group">
                  <label id="last_name" htmlFor="last_name"></label>
                  <p className="text-sm">Last Name</p>
                  <input
                    type="text"
                    name="last_name"
                    id="last_name"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder="Last Name"
                    value={state.last_name}
                    onChange={(e) => handleInputChange(e)}
                  ></input>
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="relative z-0 mb-6 w-full group">
                  <label id="email" htmlFor="email"></label>
                  <p className="text-sm">Email</p>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder="abc@gmail.com"
                    value={state.email}
                    onChange={(e) => handleInputChange(e)}
                  ></input>
                </div>
                <div className="relative z-0 mb-6 w-full group">
                  <label id="phoneno" htmlFor="phoneno"></label>
                  <p className="text-sm">Phone Number</p>
                  <input
                    type="tel"
                    name="phoneno"
                    id="phoneno"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder="Phone no"
                    value={state.phoneno}
                    onChange={(e) => handleInputChange(e)}
                  ></input>
                </div>
              </div>
              

                <div>
                <p>Message</p>
                <input
                  type="text_area"
                  name="text_area"
                  id="text_area"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder="Your message here"
                  value={state.text_area}
                  onChange={(e) => handleInputChange(e)}
                ></input>
              </div>
              <button
                type="submit"
                value={"save"}
                className="focus:ring-4 focus:outline-none rounded-lg mt-5 text-sm w-full sm:w-auto px-5 text-center  bg-[#182B4D] text-white p-3.5 font-medium  hover:bg-[#2f589e]"
              >
                Submit
                <ToastContainer
                  position="top-center"
                  autoClose={1000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
                />
              </button>
            </form>
          </div>
        </div>
        

      <div>
        <Footer />
      </div>
    </div>
  );
}
