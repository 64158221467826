import React, { useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import { HTMLInputTypeAttribute } from "react";
import Footer from "../../components/footer/Footer";
import { db } from "../../firebase";
import data from "../../utils/content.json";
import { addDoc, collection } from "firebase/firestore";
// type InputBaseProps = {
//   type?: HTMLInputTypeAttribute;
// };
const group = require("../../images/shared/team.JPG")
//const img2 = require("../../images/joinus/Image.png");

// const img1=require("../images/joinus/img1.png");

export const JoinUs = () => {
  const [state, setState] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phoneno: "",
    text_area: "",
    where:"",
    time1:"",
    education:"",
    volunteering:"",
    days:""
  
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
    console.log(state);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log(state);
    if (
      !state.first_name ||
      !state.email ||
      !state.last_name ||
      !state.phoneno ||
      !state.text_area ||
      !state.where ||
      !state.time1 ||
      !state.education ||
      !state.volunteering ||
      !state.days
    ) {
      toast.warn("Please Enter all Input fields!", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      const colRef = collection(db, "users");

      addDoc(colRef, {
        email: state.email,
        first_name: state.first_name,
        last_name: state.last_name,
        phoneno: state.phoneno,
        text_area: state.text_area,
      }).then(() => {
        setState({
          email: "",
          first_name: "",
          last_name: "",
          phoneno: "",
          text_area: "",
          where:"",
          time1:"",
          education:"",
          volunteering:"",
          days:""
        });
      });
    }
  };

  return (
    <div>
      <Navbar />
      <div>
        <div className="text-center bg-[#182B4D] w-full lg:p-16 lg:h-72 bg-auto bg-no-repeat  joinus xl:w-100">
          <h3 className="align-middle font-bold pt-5 lg:pt-0 lg:text-2xl md:text-xl text-[#f2f4f8] ">
            YOU CAN CONTRIBUTE
            <br />
            TO MAKE OTHERS LIVES
            <br />
            BETTER!
          </h3>
          <button className=" m-5 bg-white font-bold  text-[#182B4D] w-40 h-12 my-4 p-2 hover:bg-[#c8d4eb]">
            <a href="#addUser"> Join Us</a>
          </button>
        </div>
        {/* form starts here */}
        <div className="flex border-2 rounded-lg ring-2 ring-gray-300 border-slate-800 w-[80%] align-middle mt-10 justify-center m-auto h-auto mb-10">
          <div className="flex-1">
            <form
              id="addUser"
              onSubmit={handleSubmit}
              className=" align-middle m-10"
            >
              <h3 className="text-xl font-bold mb-2">Join Us</h3>

              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="relative z-0 mb-6 w-full group">
                  <label id="first_name" htmlFor="first_name"></label>
                  <p className="text-sm">First Name</p>
                  <input
                    type="text"
                    name="first_name"
                    id="first_name"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder="First Name"
                    value={state.first_name}
                    onChange={(e) => handleInputChange(e)}
                  ></input>
                </div>
                <div className="relative z-0 mb-6 w-full group">
                  <label id="last_name" htmlFor="last_name"></label>
                  <p className="text-sm">Last Name</p>
                  <input
                    type="text"
                    name="last_name"
                    id="last_name"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder="Last Name"
                    value={state.last_name}
                    onChange={(e) => handleInputChange(e)}
                  ></input>
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="relative z-0 mb-6 w-full group">
                  <label id="email" htmlFor="email"></label>
                  <p className="text-sm">Email</p>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder="abc@gmail.com"
                    value={state.email}
                    onChange={(e) => handleInputChange(e)}
                  ></input>
                </div>
                <div className="relative z-0 mb-6 w-full group">
                  <label id="phoneno" htmlFor="phoneno"></label>
                  <p className="text-sm">Phone Number</p>
                  <input
                    type="tel"
                    name="phoneno"
                    id="phoneno"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder="Phone no"
                    value={state.phoneno}
                    onChange={(e) => handleInputChange(e)}
                  ></input>
                </div>
              </div>
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="relative z-0 mb-6 w-full group">
                  <label id="where" htmlFor="where"></label>
                  <p className="text-sm">Where are you from?</p>
                  <input
                    type="text"
                    name="where"
                    id="where"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder="Eg.Pune"
                    value={state.where}
                    onChange={(e) => handleInputChange(e)}
                  ></input>
                </div>
                <div className="relative z-0 mb-6 w-full group">
                  <label id="education" htmlFor="education"></label>
                  <p className="text-sm">Education</p>
                  <input
                    type="text"
                    name="education"
                    id="education"
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder="Currently pursuing/Completed"
                    value={state.education}
                    onChange={(e) => handleInputChange(e)}
                  ></input>
                </div>
              </div>

              <div className="relative z-0 mb-6 w-full group">
                <label id="volunteering" htmlFor="volunteering"></label>

                <p className="text-sm">
                  Do you have any volunteering experience?
                </p>
                <input
                  type="text"
                  // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                  name="volunteering"
                  id="volunteering"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder="Write your previous experience"
                  value={state.volunteering}
                  onChange={(e) => handleInputChange(e)}
                ></input>
              </div>
              <div className="relative z-0 mb-6 w-full group">
                <label id="contri" htmlFor="contri"></label>

                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="relative z-0 mb-6 w-full group">
                    <label id="contri" htmlFor="contri"></label>
                    <p className="text-sm">
                      For how many days can you contribute?
                    </p>
                    <input
                      type="text"
                      name="days"
                      id="days"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder="Time period in weeks/months"
                      value={state.days}
                      onChange={(e) => handleInputChange(e)}
                    ></input>
                  </div>
                  <div className="relative z-0 mb-6 w-full group">
                    <label id="time1" htmlFor="time1"></label>
                    <p className="text-sm">
                      For how much time can you contribute?
                    </p>
                    <input
                      type="text"
                      name="time1"
                      id="time1"
                      className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder="hours per day/week"
                      value={state.time1}
                      onChange={(e) => handleInputChange(e)}
                    ></input>
                  </div>
                </div>
                <p>Message</p>
                <input
                  type="text_area"
                  name="text_area"
                  id="text_area"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder="Your message here"
                  value={state.text_area}
                  onChange={(e) => handleInputChange(e)}
                ></input>
              </div>
              <button
                type="submit"
                value={"save"}
                className="focus:ring-4 focus:outline-none rounded-lg text-sm w-full sm:w-auto px-5 text-center  bg-[#182B4D] text-white p-3.5 font-medium  hover:bg-[#2f589e]"
              >
                Submit
                <ToastContainer
                  position="top-center"
                  autoClose={1000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
                />
              </button>
            </form>
          </div>
        </div>
        <div>


          {/* group image */}
          <img
            className="h-auto w-[84%] m-auto p-auto max-w-full"
            src={group}
            alt=""
          />
        </div>
        <div className="flex text-center bg-[#182B4D] w-10/12 align-middle mt-10 justify-center m-auto h-auto mb-20">
          <div className="flex-auto m-10 md:m-16X">
            <div className="md:text-left text-xs text-white text-justify md:text-lg lg:text-lg xl:text-lg">
              {data.joinusnote}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

function handleInputChange(e: React.ChangeEvent<HTMLInputElement>): void {
  throw new Error("Function not implemented.");
}
