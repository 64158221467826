import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
const image1 = require("../../images/media/Image1.png");
const image2 = require("../../images/media/Image2.png");
const image3 = require("../../images/media/Image3.png");
const youtube = require("../../images/media/youtube.png");
const t0 = require("../../images/media/Thumbnail1.png");
const t1 = require("../../images/media/Thumbnail2.png");
const t2 = require("../../images/media/Thumbnail.png");
const t3 = require("../../images/media/Thumbnail3.png");
export default function media() {
  return (
    <div>
      <div>
        <Navbar />
      </div>
      <div className="bg-[#182B4D] md:m-0  md:px-24 md:py-8 py-2 px-1 m-0 text-white">
        <div className="m-1 lg:px-40 py-1 px-2">
          <h1 className="font-bold text-4xl text-center">
            Read Our Recent News
          </h1>
          <br />
          <h2 className="text-justify">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magni
            voluptatibus iusto dolorem porro ea nesciunt harum dolorum doloribus
            cum reprehenderit ex autem explicabo facilis, odit vero excepturi
            itaque quia obcaecati?
          </h2>
        </div>
        <div className="lg:flex m-2 p-2">
          <div className="m-2 p-2">
            <div className="m-2 p-2 flex justify-center">
              <img src={t0} alt="" />
            </div>
            <div className="md:m-2 md:p-2 text-justify">
              <h1 className="font-bold text-center">
                Don't destroy greenery and don't spoil scenery
              </h1>
              <h1>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum
                similique nesciunt
              </h1>
            </div>
          </div>
          <div className="m-1 p-2">
            <div className="m-2 p-2 flex justify-center">
              <img src={t1} alt="" />
            </div>
            <div className="md:m-2 md:p-2 text-justify">
              <h1 className="font-bold text-center">
                Don't destroy greenery and don't spoil scenery
              </h1>
              <h1>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum
                similique nesciunt
              </h1>
            </div>
          </div>
          <div className="m-1 p-2">
            <div className="m-2 p-2 flex justify-center">
              <img src={t2} alt="" />
            </div>
            <div className="md:m-2 md:p-2 text-justify">
              <h1 className="font-bold text-center">
                Don't destroy greenery and don't spoil scenery
              </h1>
              <h1>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum
                similique nesciunt
              </h1>
            </div>
          </div>
          <div className="m-1 p-2">
            <div className="m-2 p-2 flex justify-center">
              <img src={t3} alt="" />
            </div>
            <div className="md:m-2 md:p-2 text-justify">
              <h1 className="font-bold">
                Don't destroy greenery and don't spoil scenery
              </h1>
              <h1>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum
                similique nesciunt
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white-500 mx-8 my-16">
        <div className="font-bold text-black lg:flex lg:justify-center m-4 p-2 md:text-3xl text-xl">
          <div className="flex justify-center">
            <img src={youtube} alt="" />
          </div>
          <div className="md:my-2 md:p-2 flex justify-center">
            <h1>YOUTUBE VIDEOS</h1>
          </div>
        </div>
        <div className="lg:flex m-2 p-2 justify-center">
          <div className="m-2 p-2 flex justify-center">
            <iframe
              width="md:425"
              height="md:225"
              src="https://www.youtube.com/embed/1RU9JFONJN4"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </div>
          <div className="m-2 p-2 flex justify-center">
            <iframe
              width="md:425"
              height="md:225"
              src="https://www.youtube.com/embed/1RU9JFONJN4"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </div>
          <div className="m-2 p-2 flex justify-center">
            <iframe
              width="md:425"
              height="md:225"
              src="https://www.youtube.com/embed/9UdAsPW4ex8"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </div>
        </div>
      </div>
      <div className="bg-[#182B4D] md:px-24 md:py-8 py-2 px-1 my-16">
        <div className="bg-[#182B4D] md:p-10 md:mx-4 md:my-0 p-10 mx-4 my-0 ">
          {/* <h5 className='text-white md:font-medium text-center'>TOP NEWS</h5> */}
          <br />
          <h1 className="md:text-5xl font-bold text-white text-center text-3xl">
            Achievements and awards
          </h1>
          <br />
          <br />
          <div className="flex justify-center">
            <button className="bg-white hover:bg-gray-500 hover:text-white text-[#182B4D] p-3 rounded-md font-medium">
              Read More
            </button>
          </div>
        </div>
        <div className="bg-white p-3 my-8 md:mx-16 mx-10 rounded-lg">
          <div className="md:flex m-1 p-1">
            <div className="m-1 md:w-1/3 p-1 flex justify-evenly">
              <img src={image1} alt="" />
            </div>
            <div className="m-1 md:w-2/3 p-1 md:text-left text-justify">
              <h4 className="font-bold">
                Don't destroy greenery and don't spoil scenery.
              </h4>

              <h6 className="text-justify">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt
                voluptatum aut dolorem perferendis optio laudantium tempore
                libero laborum aliquam.hjdbvDIJVV
              </h6>
            </div>
          </div>
          <div className="md:flex m-1 p-1">
            <div className="m-1 md:w-1/3 p-1 flex justify-evenly">
              <img src={image2} alt="" />
            </div>
            <div className="m-1 md:w-2/3 p-1 md:text-left text-jusitfy">
              <h4 className="font-bold">
                Caring for the present is caring for the future
              </h4>

              <h6 className="text-justify">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt
                voluptatum aut dolorem perferendis optio laudantium tempore
                libero laborum aliquam.dhoqi
              </h6>
            </div>
          </div>
          <div className="md:flex m-1 p-1">
            <div className="m-1 md:w-1/3 p-1 flex justify-evenly">
              <img src={image3} alt="" />
            </div>
            <div className="m-1 md:w-2/3 p-1 md:text-left text-justify">
              <h4 className="font-bold">
                Why saving wildlife is extremely important ?
              </h4>

              <h6 className="text-justify">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt
                voluptatum aut dolorem perferendis optio laudantium tempore
                libero laborum aliquam.hifhios
              </h6>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Footer />
      </div>
    </div>
  );
}
