import React from "react";
import Footer from "../../components/footer/Footer";
// const logo_admin = require("../../images/shared/Logo.png")

export default function AdminLogin() {
  return (
    <div>
      <div className="flex justify-items-start">
        <div className=" lg:mx-10 lg:m-2 md:mx-5 md:m-2 mx-2 m-2 ">
          {/* //<img className="lg:h-[100%] lg:w-20 md:h[100%] md:w-16 h-[100%] w-16 " src={logo_admin} /> */}
        </div>
      </div>
      <br></br>
      <br></br>

      <section>
        <div className="flex justify-center mx-auto my-10">
          <form className="bg-slate-200 shadow-lg lg:w-[58vh] md-[15vh] px-4 md:px-24 h-[45vh] rounded lg-px-2 pt-6 pb-8 mb-4">
            <p className="pb-3 mb-5 text-center text-xl">
              Log in to admin account
            </p>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="emailid"
              >
                Email Address
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="emailid"
                type="text"
                placeholder="Email"
              ></input>
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="password"
              >
                Your Password
              </label>
              <div className="flex items-end justify-end">
                <a href="#" className="inline-block align-baseline">
                  {" "}
                  hide{" "}
                </a>
              </div>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="password"
                type="text"
                placeholder="Password"
              ></input>
            </div>

            {/* <div className="mb-4">
                            <label
                                className="block text-gray-700 text-sm font-bold mb-2"
                                htmlFor="emailid"
                            >
                                EmailID
                            </label>
                            <input
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="emailid"
                                type="email"
                                placeholder="Email"
                            ></input>
                        </div> */}
            {/* <div className="flex items-center md:justify-end justify-center">
                            <a
                                className="inline-block align-baseline underline  md:text-center font-bold text-sm text-[#182D4B] hover:text-blue-800"
                                href="#"
                            >
                                Forget your password?
                            </a>
                            
                        </div> */}

            <div className="flex justify-center pt-8 ">
              <button className="bg-gray-400 rounded-lg text-xl lg:text-center text-center lg:px-36 lg:py-1 px-24">
                Log in
              </button>
            </div>
          </form>
        </div>
      </section>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div>
        <Footer />
      </div>
    </div>
  );
}
