import { FormEvent, useState } from "react";
import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import { ClassNames } from "@emotion/react";
import { db } from "../../firebase";
import { addDoc, collection, doc, getDoc, getDocs } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import data from "../../utils/content.json";
const donateimg = require("../../images/donation/donatebox.jpg");
const donateimg2 = require("../../images/donation/makeachange.jpg");
const tempqr = require("../../images/donation/qrcode2.jpg");

export default function Donation() {
  const [state, setState] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    email: "",
    phoneno: "",
    amount: "",
    text_area: "",
  });
  
  // script to be added by manas

  // const actualBtn = document.getElementById("actual-btn");

  // const fileChosen = document.getElementById("file-chosen");

  // actualBtn.addEventListener("change", function () {
  // fileChosen.textContent = this.files[0].name;
  // });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
    console.log(state);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log(state);
    if (
      !state.first_name ||
      !state.middle_name ||
      !state.last_name ||
      !state.amount ||
      !state.phoneno ||
      !state.text_area ||
      !state.email
    ) {
      toast.warn("Please Enter all Input fields!", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      const colRef = collection(db, "donors");

      addDoc(colRef, {
        first_name: state.first_name,
        middle_name: state.middle_name,
        last_name: state.last_name,
        phoneno: state.phoneno,
        amount: state.amount,
        email: state.email,
        text_area: state.text_area,
      }).then(() => {
        setState({
          first_name: "",
          middle_name: "",
          last_name: "",
          phoneno: "",
          amount: "",
          email: "",
          text_area: "",
        });
      });
    }
  };

  return (
    <div>
      <div>
        <Navbar />
      </div>
      {/*donation for education*/}
      <section>
        <div className="bg-[#182B4D] p-10  md:flex md:flex-row justify-around text-white">
          <div className="md:w-[40%]">
            <div className="flex flex-row">
              <div className="pb-5 ">__________ </div>

              <h2 className=" tracking-widest font-normal text-base  ml-10 pt-2">
                DONATE
              </h2>
            </div>

            <h1 className="md:ml-20 sm:mx-auto text-3xl md:text-4xl font-semibold text-center md:text-left">
              Making a donation <br />
              for Education
            </h1>
            <h2 className="font-light md:ml-20  mt-8 justify-items-center text-justify md:text-left">
              {data.DonateUsQuote}
            </h2>
            <h2 className="flex font-light md:ml-20 mt-1 justify-end lg:mr-20">
              {data.DonateUsAuthor}
            </h2>
            <div className="flex justify-center items-center md:justify-start">
              <a
                href="#donate_section"
                className="bg-white text-[#182B4D] p-3 font-semibold rounded md:ml-20 mb-5 mt-8"
              >
                Donate now
              </a>
            </div>
          </div>
          <div className="md:w-[40%]   md:mb-5 mx-auto ">
            <img
              className="sm:h-full lg:w-full sm:mx-auto "
              src={donateimg2}
              alt=""
            />
          </div>
        </div>
      </section>

      {/*donate now*/}
      <section>
        <div className="bg-[#182B4D] md:p-20 mt-10 p-10">
          <div className="md:flex md:justify-between">
            <div className="text-white ml-15 ">
              <h1 className="text-3xl font-semibold md:ml-5 py-4 ">
                Donate Now
              </h1>
              <h2 className="text-xl md:font-semibold md:ml-10 ">
                Bank Details{" "}
              </h2>
              
                <div>
                  <h2 className="text-xl md:font-semibold md:ml-10 py-2">
                  Merchant Name: {data.DonateUsMerchantName}
                  </h2>
                </div>
                <div>
                  <h2 className="text-xl md:font-semibold  md:ml-10 py-2">
                    UPI Id: {data.DonateUsUpiId}
                  </h2>
                </div>
              
              <div className="md:flex md:ml-10 py-4">
                <div>
                  <h2 className="text-xl md:font-semibold ">
                    Account Number: {data.DonateUsAccountNo}
                  </h2>
                </div>
                <div>
                  <h2 className="text-xl md:font-semibold lg:ml-3">
                    Bank Name: {data.DonateUsBankName}
                  </h2>
                </div>
              </div>

              <h2 className="text-xl md:font-semibold md:ml-10 pb-4">
                IFSC Code: {data.DonateUsIFSC}
              </h2>
            </div>
            <div className=" mx-auto">
              <img className="mx-auto sm:width-full" src={tempqr} alt="" />
            </div>
          </div>
          <div className="bg-white text-black p-3 m-5 rounded md:w-[96%] text-xl font-semibold">
            <a href="#donate_section" className="flex justify-center">
              Fill form and upload screenshot
            </a>
          </div>

          <div className="md:flex justify-items justify-center">
            <div className="flex justify-center items-center md:items-left md:w-[50%]">
              <button className="bg-white text-black w-full rounded m-5 p-3 text-xl font-semibold ">
                80G Certificate
              </button>
            </div>
            <div className="flex justify-center items-center md:items-left md:w-[50%]">
              <button className="bg-white text-black w-full rounded m-5 p-3 text-xl font-semibold ">
                80G Certificate
              </button>
            </div>
          </div>
        </div>
      </section>

      {/*note */}
      {/* <section>
        <div
          className="bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-10 py-3 my-5"
          role="alert"
        >
          <p className="font-bold">Note</p>
          <p className="text-sm">Please upload screenshot of the payment done by UPI, Netbanking mode in the form</p>
        </div>
      </section> */}

      {/*form*/}
      {/* flex p-6 rounded-lg ring-gray-300 w-[80%] align-middle border-slate-800 shadow-lg bg-white max-w-md m-auto my-5 */}
      <div
        id="donate_section"
        className="flex border-2  md:m-auto m-4 rounded-lg ring-2 ring-gray-300 border-slate-800 md:w-[60%] lg:w-[45%] align-middle mt-10 justify-center  h-auto mb-10"
      >
        <form
          id="addDonor"
          onSubmit={handleSubmit}
          className=" align-middle my-10 mx-5"
        >
          <h3 className="text-xl font-bold mb-2">Donation Form</h3>
          <div className="grid grid-cols-2 gap-4">
            <div className="form-group mb-6">
              <label
                id="first_name"
                htmlFor="first_name"
                className="form-label inline-block mb-2 text-gray-700"
              >
                First Name
              </label>
              <input
                type="text"
                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                id="first_name"
                name="first_name"
                aria-describedby="emailHelp123"
                placeholder="First name"
                value={state.first_name}
                onChange={(e) => handleInputChange(e)}
              ></input>
            </div>
            <div className="form-group mb-6">
              <label
                id="middle_name"
                htmlFor="middle_name"
                className="form-label inline-block mb-2 text-gray-700"
              >
                Middle Name
              </label>
              <input
                type="text"
                className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                id="middle_name"
                name="middle_name"
                aria-describedby="emailHelp124"
                placeholder="Middle Name"
                value={state.middle_name}
                onChange={(e) => handleInputChange(e)}
              ></input>
            </div>
          </div>
          <div className="form-group mb-6">
            <label
              id="last_name"
              htmlFor="last_name"
              className="form-label inline-block mb-2 text-gray-700"
            >
              Last Name
            </label>
            <input
              type="text"
              className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="last_name"
              name="last_name"
              aria-describedby="emailHelp124"
              placeholder="Last Name"
              value={state.last_name}
              onChange={(e) => handleInputChange(e)}
            ></input>
          </div>
          <div className="form-group mb-6">
            <label
              id="email"
              htmlFor="email"
              className="form-label inline-block mb-2 text-gray-700"
            >
              Email Address
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              placeholder="Email id"
              value={state.email}
              onChange={(e) => handleInputChange(e)}
            ></input>
          </div>
          <div className="form-group mb-6">
            <label
              id="phoneno"
              htmlFor="phoneno"
              className="form-label inline-block mb-2 text-gray-700"
            >
              Phone Number
            </label>
            <input
              type="tel"
              name="phoneno"
              id="phoneno"
              className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              placeholder="Whatsapp phone number"
              value={state.phoneno}
              onChange={(e) => handleInputChange(e)}
            ></input>
          </div>
          <div className="form-group mb-6">
            <label
              id="amount"
              htmlFor="amount"
              className="form-label inline-block mb-2 text-gray-700"
            >
              Amount Donated
            </label>
            <input
              type="number"
              id="amount"
              name="amount"
              className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              value={state.amount}
              onChange={(e) => handleInputChange(e)}
              placeholder="Amount in Rs"
            ></input>
          </div>
          <div className="form-group mb-6">
            <label
              id="text_area"
              htmlFor="text_area"
              className="form-label inline-block mb-2 text-gray-700"
            >
              Enter a message
            </label>
            <input
              className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              id="text_area"
              name="text_area"
              placeholder="Message"
              value={state.text_area}
              onChange={(e) => handleInputChange(e)}
            ></input>
          </div>
          <div className="flex justify-between">
            <input
              type="file"
              id="actual-btn"
              accept="image/png, image/jpeg"
              className="hidden"
            />
            <div className="w-[45%] flex flex-col">
              <label
                htmlFor="actual-btn"
                className=" w-full px-5 py-2.5 bg-[#182B4D] text-white font-medium text-xs leading-tight  uppercase rounded shadow-md hover:bg-[#2f589e] hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
                Upload Screenshot
              </label>
            </div>

            <button
              type="submit"
              value={"save"}
              className=" w-[45%] px-auto py-2.5 bg-[#182B4D] text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-[#2f589e] hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out">
              Generate Receipt
            </button>
          </div>
          <span id="file-chosen">No file chosen</span>
        </form>
      </div>

      {/*contribute to underpriviledged children*/}
      <section>
        <div className="md:flex m-5 md:mx-10 justify-around my-20">
          <div className="md:w-1/3 ">
            <h1 className="font-bold text-2xl md:my-5">
              How can you contribute to help underpriviledged children?
            </h1>
          </div>
          <div className="md:w-1/3 my-5 ">
            <h3 className="font-light text-justify md:text-left">
              {" "}
              Time is the most precious asset which we as a human beings have so you can spend your time with our kids.
Some people lack the availability of time but they have the strong will power so they can help us in monetary items. 
Education can mold the lives of children and provide them the opportunities they need. 
{" "}
            </h3>
          </div>
          <div className="md:w-1/3 my-5 md:ml-5">
            <h3 className="font-light text-justify md:text-left">
              {" "}
              Young minds suffering from childhood poverty are deprived of these opportunities, but you can help them by donating to an education @dadachishala in Pune, Maharashtra, India. We also accept online donation for building a better life for children in need.{" "}
            </h3>
          </div>
        </div>
      </section>

      {/*how we use your donation*/}
      <section>
        <div className="md:flex m-5 md:mx-10 justify-around my-20">
          <div className="md:w-1/3 ">
            <h1 className="font-bold text-2xl md:mt-5">
              How we use your donation?
            </h1>
          </div>
          <div className="md:w-1/3 my-5">
            <h3 className="font-light text-justify md:text-left ">
              {" "}
              Your donations will be used to carry out different requirements of our children like the school fees, weekly trips to various places, stationary items for daily use etc.{" "}
            </h3>
          </div>
          <div className="md:w-1/3 my-5">
            <h3 className="font-light text-justify md:text-left md:ml-5">
              {" "}
              This is a chance for you to help build a future and save a life.
Being the part of the society, it is our duty and responsibility to pay back something to the society in return. This is one of the ways in which you can do that.{" "}
            </h3>
          </div>
        </div>
      </section>

      <div>
        <Footer />
      </div>
    </div>
  );
}

function handleInputChange(e: React.ChangeEvent<HTMLInputElement>): void {
  throw new Error("Function not implemented.");
}
