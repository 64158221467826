import { HTMLInputTypeAttribute } from "react";
type InputBaseProps = {
  type?: HTMLInputTypeAttribute;
};
export const Adminuserpage = (type: InputBaseProps) => {
  return (
    <div>
      <div className="m-10 p-10">
        
        
      </div>
      <form className="w-full max-w-lg m-auto pt-4 border-2 align-middle px-4 mt-24">
      
        <div className="flex flex-wrap -mx-3 mb-6">
           
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <h3 className="mb-2">Page</h3>
            <div className="relative">
                
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-state"
              >
                <option>Home page</option>
                <option>Branches</option>
                <option>Media</option>
                <option>Donate Us</option>
                <option>Join Us</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 px-3">
            <h3 className="mb-2">Branch</h3>
            <div className="relative">
              <select
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-state"
              >
                <option>New Mexico</option>
                <option>Missouri</option>
                <option>Texas</option>
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <input type="file" name="fileToUpload" id="fileToUpload"></input>
          </div>
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <h3>Enter Text</h3>
            <textarea name="entertext" id="entertext" className="border-2 border-neutral-600"></textarea>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-6">
           
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <button className="bg-neutral-600 hover:bg-neutral-700 text-white font-bold py-2 px-4 rounded-none">
  Save Changes
</button>
        </div>
        </div>
      </form>
      </div>
  
  );
};
