import { initializeApp} from 'firebase/app'
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  where,
  orderBy,
  serverTimestamp,
  getDoc,
  updateDoc
} from 'firebase/firestore'
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDexgAKHgcftc3ExImZess0zbBZvEa_FNg",
  authDomain: "dadachishala-website-1e68b.firebaseapp.com",
  projectId: "dadachishala-website-1e68b",
  storageBucket: "dadachishala-website-1e68b.appspot.com",
  messagingSenderId: "735345990521",
  appId: "1:735345990521:web:4881c597cb931dd9c7eda8"
};

initializeApp(firebaseConfig)
const db = getFirestore()
const storage = getStorage();
export  {db,storage};