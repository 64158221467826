import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
//const ham = require("../images/shared/bars-solid.svg");
//const Ham = require("../images/shared/bars-solid.svg") ;
const logo = require("../../images/shared/Logo.png");

export default function Navbar() {
  return (
    <div>
      <div className=" flex justify-items-start  shadow-lg ">
        <div className=" lg:mx-10 lg:m-2 md:mx-5 md:m-2 mx-2 m-2 ">
          <a href="/">
            <img
              className="lg:h-[100%] lg:w-20 md:h[100%] md:w-16 h-[100%] my-auto w-20 "
              src={logo}
              alt=""
            />
          </a>
        </div>
        <div className="  lg:w-[60%] font-bold  lg:text-2xl lg:px-4 lg:py-4  md:w-[50%] md:text-lg md:px-2 md:py-6 w-[50%] text-xs px-1 py-2 my-4  ">
          <h1>Educare Educational Trust Dadachi Shala </h1>
        </div>
        <div className=" flex justify-center items-center">
          <div className=" md:flex ">
            <a href="/donation">
              <button
                className="rounded-md bg-[#182B4D] text-white lg:text-lg lg:m-2 lg:p-2 lg:w-40 lg:my-4 lg:font-medium hover:bg-[#2f589e]
          md:m-2 md:px-2 md:p-2 md:w-24 md:my-5 m-1 px-2 p-1 w-[80%]  text-xs   "
              >
                Donate Us
              </button>
            </a>
            <a href="/joinus">
              <button
                className="rounded-md bg-[#182B4D] text-white lg:text-lg lg:m-2 lg:p-2 lg:w-40 lg:my-4 lg:font-medium hover:bg-[#2f589e]
          md:m-2 md:px-2 md:p-2 md:w-24 md:my-5 m-1 px-2 p-1 w-[80%]  text-xs "
              >
                Join Us
              </button>
            </a>
          </div>
          <div className="  py-4 group md:mr-10">
            <button className="md:ml-6 m-2">
              <MenuIcon> </MenuIcon>
            </button>
            <div className=" group-hover:visible invisible absolute right-10 w-38 py-2 mt-4 z-10 bg-gray-100 rounded-md shadow-xl">
              <a
                href="/branches"
                className="block px-4 py-2 text-sm hover:bg-gray-400 hover:text-white"
              >
                Branches
              </a>
              <a
                href="/team"
                className="block px-4 py-2 text-sm  text-black hover:bg-gray-400 hover:text-white"
              >
                Team
              </a>
              <a
                href="/aboutus"
                className="block px-4 py-2 text-sm  text-black hover:bg-gray-400 hover:text-white"
              >
                About Us
              </a>
              <a
                href="/media"
                className="block px-4 py-2 text-sm  text-black hover:bg-gray-400 hover:text-white"
              >
                Media
              </a>
              <a
                href="/contactus"
                className="block px-4 py-2 text-sm  text-black hover:bg-gray-400 hover:text-white"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
