import React from "react";

import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
//const photo = require("../../images/team/Image.png");
const hp = require("../../images/developer/Haripriya Kulkarni.jpg");
const ng = require("../../images/developer/Nehil Gajare.jpg");
const mt = require("../../images/developer/Maithili Tawde.JPG");
const kt = require("../../images/developer/kartiki kotawar.jpeg");
const mk = require("../../images/developer/Manas Kshatriya.jpeg");
const rk = require("../../images/developer/rushi.jpg")
export default function developer() {
  return (
    <div className="bg-black">
      <section className="">
        <div className=" flex-col justify-center items-center p-8">
          <div className=" text-center flex-col items-center lg:p-10 lg:pt-2 md:p-10 justify-center">
            <h1 className="lg:text-4xl md:text-3xl text-xl text-white font-bold">
              Meet the Developers
            </h1>
            <br />
            {/* <p className="text-gray-400 md:text-lg text-sm  md:text-center text-justify ">
              Our Passionate and Enthusiastic{" "}
              <span className="font-semibold text-gray-300">
                {" "}
                Core Team members
              </span>
              <br />
              include
            </p> */}
          </div>
          <div className=" md:flex items-center    lg:my-5 md:mx-4 my-4 justify-evenly">
            <div className="flex-col   ">
              <div className="">
                <img
                  src={mk}
                  className="rounded-full m-auto  lg:w-[35vh] md:w-[25vh]  w-[70%]  "
                  alt=""
                />
              </div>
              <div className=" md:p-1 md:pb-0  md:text-base pt-2 text-base text-center  ">
                <p className="font-medium text-white">Manas Kshatriya</p>
              </div>
              <div className=" text-center md:text-base text-sm ">
                <p className="text-gray-400 ">Team Member</p>
              </div>
              <div className=" flex justify-center items-center ">
                <div className="md:px-2 md:p-1  px-2 py-1  ">
                  <a href="sa">
                    <button>
                      {" "}
                      <TwitterIcon
                        className="icons"
                        style={{ color: "#FAF9F6" }}
                      ></TwitterIcon>{" "}
                    </button>
                  </a>
                </div>
                <div className="md:px-2  px-2 py-1 ">
                  <a href="https://www.facebook.com/abhijeet.dada.96/">
                    <button>
                      <FacebookIcon
                        className="icons"
                        style={{ color: "#FAF9F6" }}
                      ></FacebookIcon>
                    </button>
                  </a>
                </div>
                <div className="md:px-2  px-2 py-1 ">
                  <a href="https://www.instagram.com/abhijeet_pokharnikar_/">
                    <button>
                      <InstagramIcon
                        className="icons"
                        style={{ color: "#FAF9F6" }}
                      ></InstagramIcon>
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div className="flex-col   ">
              <div className="">
                <img
                  src={rk}
                  className="rounded-full m-auto  lg:w-[35vh] md:w-[25vh]  w-[70%]  "
                  alt=""
                />
              </div>
              <div className=" md:p-1 md:pb-0  md:text-base pt-2 text-base text-center  ">
                <p className="font-medium text-white">Rushikesh Korade</p>
              </div>
              <div className=" text-center md:text-base text-sm ">
                <p className="text-gray-400 ">Team Head</p>
              </div>
              <div className=" flex justify-center items-center ">
                <div className="md:px-2 md:p-1  px-2 py-1  ">
                  <a href="sa">
                    <button>
                      {" "}
                      <TwitterIcon
                        className="icons"
                        style={{ color: "#FAF9F6" }}
                      ></TwitterIcon>{" "}
                    </button>
                  </a>
                </div>
                <div className="md:px-2  px-2 py-1 ">
                  <a href="https://www.facebook.com/abhijeet.dada.96/">
                    <button>
                      <FacebookIcon
                        className="icons"
                        style={{ color: "#FAF9F6" }}
                      ></FacebookIcon>
                    </button>
                  </a>
                </div>
                <div className="md:px-2  px-2 py-1 ">
                  <a href="https://www.instagram.com/abhijeet_pokharnikar_/">
                    <button>
                      <InstagramIcon
                        className="icons"
                        style={{ color: "#FAF9F6" }}
                      ></InstagramIcon>
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div className="flex-col   ">
              <div className="">
                <img
                  src={ng}
                  className="rounded-full m-auto  lg:w-[35vh] md:w-[25vh]  w-[70%]  "
                  alt=""
                />
              </div>
              <div className=" md:p-1 md:pb-0  md:text-base pt-2 text-base text-center  ">
                <p className="font-medium text-white">Nehil Gajare</p>
              </div>
              <div className=" text-center md:text-base text-sm ">
                <p className="text-gray-400 ">Team Member</p>
              </div>
              <div className=" flex justify-center items-center ">
                <div className="md:px-2 md:p-1  px-2 py-1  ">
                  <button>
                    {" "}
                    <TwitterIcon
                      className="icons"
                      style={{ color: "#FAF9F6" }}
                    ></TwitterIcon>{" "}
                  </button>
                </div>
                <div className="md:px-2  px-2 py-1 ">
                  <button>
                    <FacebookIcon
                      className="icons"
                      style={{ color: "#FAF9F6" }}
                    ></FacebookIcon>
                  </button>
                </div>
                <div className="md:px-2  px-2 py-1 ">
                  <a href="s">
                    <button>
                      <InstagramIcon
                        className="icons"
                        style={{ color: "#FAF9F6" }}
                      ></InstagramIcon>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className=" md:flex my-12 justify-center">
            <div className="lg:mx-10 md:mx-4 my-10  ">
              <div className="flex-col  ">
                <div className="">
                  <img
                    className="rounded-full m-auto  lg:w-[35vh] md:w-[25vh]  w-[70%]  "
                    src={hp}
                    alt=""
                  />
                </div>
                <div className=" md:p-1 md:pb-0  md:text-base pt-2 text-base text-center  ">
                  <p className="font-medium text-white">Haripriya Kulkarni</p>
                </div>
                <div className=" text-center md:text-base text-sm ">
                  <p className="text-gray-400 ">Team Member</p>
                </div>
                <div className=" flex justify-center items-center ">
                  <div className="md:px-2 md:p-1  px-2 py-1  ">
                    <button>
                      {" "}
                      <TwitterIcon
                        className="icons"
                        style={{ color: "#FAF9F6" }}
                      ></TwitterIcon>{" "}
                    </button>
                  </div>
                  <div className="md:px-2  px-2 py-1 ">
                    <button>
                      <FacebookIcon
                        className="icons"
                        style={{ color: "#FAF9F6" }}
                      ></FacebookIcon>
                    </button>
                  </div>
                  <div className="md:px-2  px-2 py-1 ">
                    <a href="s">
                      <button>
                        <InstagramIcon
                          className="icons"
                          style={{ color: "#FAF9F6" }}
                        ></InstagramIcon>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:mx-12 md:mx-4 my-10">
              <div className="flex-col  ">
                <div className="">
                  <img
                    className="rounded-full m-auto  lg:w-[35vh] md:w-[25vh]  w-[70%]  "
                    src={mt}
                    alt=""
                  />
                </div>
                <div className=" md:p-1 md:pb-0  md:text-base pt-2 text-base text-center  ">
                  <p className="font-medium text-white">Maithili Tawde </p>
                </div>
                <div className=" text-center md:text-base text-sm ">
                  <p className="text-gray-400 ">Team Member</p>
                </div>
                <div className=" flex justify-center items-center ">
                  <div className="md:px-2 md:p-1  px-2 py-1  ">
                    <button>
                      {" "}
                      <TwitterIcon
                        className="icons"
                        style={{ color: "#FAF9F6" }}
                      ></TwitterIcon>{" "}
                    </button>
                  </div>
                  <div className="md:px-2  px-2 py-1 ">
                    <a href="as">
                      <button>
                        <FacebookIcon
                          className="icons"
                          style={{ color: "#FAF9F6" }}
                        ></FacebookIcon>
                      </button>
                    </a>
                  </div>
                  <div className="md:px-2  px-2 py-1 ">
                    <a href="as">
                      <button>
                        <InstagramIcon
                          className="icons"
                          style={{ color: "#FAF9F6" }}
                        ></InstagramIcon>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="lg:mx-14 md:mx-4 my-10 ">
              <div className="flex-col   ">
                <div className="">
                  <img
                    className="rounded-full m-auto  lg:w-[35vh] md:w-[25vh]  w-[70%]  "
                    src={kt}
                    alt=""
                  />
                </div>
                <div className=" md:p-1 md:pb-0  md:text-base pt-2 text-base text-center  ">
                  <p className="font-medium text-white">Kartiki Kotawar</p>
                </div>
                <div className=" text-center md:text-base text-sm ">
                  <p className="text-gray-400 ">Team Member</p>
                </div>
                <div className=" flex justify-center items-center ">
                  <div className="md:px-2 md:p-1  px-2 py-1  ">
                    <button>
                      {" "}
                      <TwitterIcon
                        className="icons"
                        style={{ color: "#FAF9F6" }}
                      ></TwitterIcon>{" "}
                    </button>
                  </div>
                  <div className="md:px-2  px-2 py-1 ">
                    <button>
                      <FacebookIcon
                        className="icons"
                        style={{ color: "#FAF9F6" }}
                      ></FacebookIcon>
                    </button>
                  </div>
                  <div className="md:px-2  px-2 py-1 ">
                    <a href="a">
                      <button>
                        <InstagramIcon
                          className="icons"
                          style={{ color: "#FAF9F6" }}
                        ></InstagramIcon>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
