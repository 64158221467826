import React from "react";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import { ChangeList1 } from "../../components/adminChangeList/changeList1";

export default function adminChangeList2() {
    return (

        <div>
            <div>
                <Navbar />
            </div>
            {/*title */}
            <section>
                <div>
                    <h1 className="font-bold text-3xl m-10">Changes Page List</h1>
                </div>
            </section>

            {/*changes button*/}
            <div>
                <ChangeList1 sectionname="Home" />
                <ChangeList1 sectionname="Branches" />
                <ChangeList1 sectionname="Team" />
                <ChangeList1 sectionname="Donation" />
                <ChangeList1 sectionname="About Us"/>
                <ChangeList1 sectionname="Form" />
                <ChangeList1 sectionname="Other" />
            </div>
           

            <div>
                <Footer />
            </div>

        </div>
    );
}